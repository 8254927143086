<template>
  <div v-if="canViewManageReceipts" class="">
    <router-view></router-view>
  </div>
  <content-holder v-else :title="'Manage Receipts'" class="p-4">
    <unauthorized-access></unauthorized-access>
  </content-holder>
</template>

<script>
import UnauthorizedAccess from "@/components/shared/UnauthorizedAccess.vue";
import ContentHolder from "../components/ContentHolder.vue";
import { views, role } from "@/helpers/login_details.js";

export default {
  components: {
    UnauthorizedAccess,
    ContentHolder,
  },
  created() {
    if (views.includes("ManageReceipts")) {
      this.canViewManageReceipts = true;

      if (role === "director") {
        this.componentId = "viewReceipts";
      } else {
        this.componentId = "updateReceipt";
      }
    } else {
      this.canViewManageReceipts = false;
    }
  },
};
</script>

<style></style>
